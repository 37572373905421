import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import { banking } from "../../data/parents'-section"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"

function Banking(props) {
  return (
    <div>
      <Layout>
        <SEO
          title={banking.title}
          description={getWords(banking.content, 60)}
        />
        <GeneralComp {...banking} />
      </Layout>
    </div>
  )
}

export default Banking
